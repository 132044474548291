<template>
  <div style="padding: 1rem" v-if="!loading && !done">
    <div style="display: flex; flex-flow: row wrap; align-items: center">
      <img
        style="width: 10px; height: auto"
        src="../assets/common-01.png"
        @click="goBack"
      />
    </div>

    <p style="margin: 2rem 0 1rem 0; font-size: 1.5rem; font-weight: bold">
      {{ $t("register.item_01") }}
    </p>

    <div style="padding: 2rem 0 0 0">
      <p style="margin: 0 0 0.5rem 0; font-size: 1rem; font-weight: bold">
        {{ $t("common.item_07") }}
      </p>
      <p style="margin: 0 1rem 0.5rem 1rem; color: red" v-if="emailMsg">
        {{ emailMsg }}
      </p>
      <div>
        <input
          style="
            padding: 0.8rem 80px 0.8rem 1rem;
            width: 100%;
            color: #898989;
            background-color: #f6f7fa;
            border-radius: 25px;
            outline: none;
            border: none;
          "
          :placeholder="$t('common.item_08')"
          v-model="emailAcc"
          @focus="
            () => {
              emailMsg = '';
            }
          "
        />
      </div>
    </div>

    <div style="padding: 2rem 0 0 0">
      <p style="margin: 0 0 0.5rem 0; font-size: 1rem; font-weight: bold">
        {{ $t("common.item_11") }}
      </p>
      <p style="margin: 0 1rem 0.5rem 1rem; color: red" v-if="pwdMsg">
        {{ pwdMsg }}
      </p>
      <div style="position: relative">
        <input
          style="
            padding: 0.8rem 80px 0.8rem 1rem;
            width: 100%;
            color: #898989;
            background-color: #f6f7fa;
            border-radius: 25px;
            outline: none;
            border: none;
          "
          :type="pwdShow ? 'text' : 'password'"
          :placeholder="$t('common.item_12')"
          v-model="pwd"
          @focus="
            () => {
              pwdMsg = '';
            }
          "
        />
        <img
          style="
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translate(0, -50%);
            width: 30px;
            height: auto;
          "
          :src="pwdImg(pwdShow)"
          @click="
            () => {
              pwdShow = pwdShow ? false : true;
            }
          "
        />
      </div>
    </div>

    <div style="padding: 2rem 0 0 0" v-if="checkCode">
      <p style="margin: 0 0 0.5rem 0; font-size: 1rem; font-weight: bold">
        {{ $t("common.item_34") }}
      </p>
      <p style="margin: 0 1rem 0.5rem 1rem; color: red" v-if="codeMsg">
        {{ codeMsg }}
      </p>
      <div
        style="
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: space-between;
        "
      >
        <input
          style="
            padding: 0.8rem 1rem;
            width: 65%;
            color: #898989;
            background-color: #f6f7fa;
            border-radius: 25px;
            outline: none;
            border: none;
          "
          :placeholder="$t('common.item_35')"
          v-model="code"
          @focus="
            () => {
              codeMsg = '';
            }
          "
        />
        <VCode v-model:value="validCode"></VCode>
      </div>
    </div>

    <q-btn
      class="full-width"
      style="
        margin: 2rem 0 0 0;
        background-color: #387bf8;
        padding: 0.7rem 0;
        font-size: 1rem;
        color: #fff;
      "
      rounded
      no-caps
      :label="$t('register.item_02')"
      @click="submit"
    />

    <p
      style="
        margin: 1rem 0 0 0;
        text-align: right;
        color: #387bf8;
        text-decoration: underline;
      "
    >
      <span @click="goLogin">{{ $t("register.item_03") }}</span>
    </p>
  </div>

  <div
    style="
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translate(0, -50%);
      text-align: center;
    "
    v-if="!loading && done"
  >
    <img style="width: 130px; height: auto" src="../assets/common-04.png" />

    <p style="margin: 1rem 3rem; font-size: 1rem">
      <span>{{ $t("common.item_16") }}</span
      ><br />
      <span>{{ emailAcc }}.</span><br />
      <span>{{ $t("common.item_17") }}</span>
    </p>

    <q-btn
      style="
        margin: 1.5rem 0 0 0;
        background-color: #387bf8;
        padding: 0.7rem 2.3rem;
        font-size: 1rem;
        color: #fff;
      "
      rounded
      no-caps
      :label="$t('common.item_15')"
      @click="init"
    />
  </div>
</template>

<script>
import VCode from "@/components/vcode.vue";
export default {
  components: {
    VCode,
  },
  data() {
    return {
      httpProcess: false,
      loading: false,
      done: false,

      emailMsg: "",
      emailAcc: "",
      pwdShow: false,
      pwdMsg: "",
      pwd: "",

      checkCode: false,
      validCode: "",
      codeMsg: "",
      code: "",
    };
  },
  created() {
    this.showCode();
    var token;
    var arr = document.location.toString().split("token=");
    if (arr.length == 2 && arr[1].length > 0) {
      var index = arr[1].indexOf("&");
      if (index > 0) {
        token = arr[1].substring(0, index);
      } else if (index == -1) {
        token = arr[1];
      }
    }
    if (token) this.check(token);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goLogin() {
      this.$router.push({ path: "/login" });
    },
    pwdImg(v) {
      if (v) {
        return require("../assets/common-02.png");
      } else {
        return require("../assets/common-03.png");
      }
    },
    init() {
      this.done = false;
      this.emailAcc = "";
      this.pwd = "";
      this.code = "";
    },
    showCode() {
      var code = parseInt(window.sessionStorage["code"]);
      if (code && code >= 3) {
        this.checkCode = true;
      }
    },
    submit() {
      var self = this;
      self.$buryPoint("user_register", {});
      if (!self.emailAcc) {
        self.emailMsg = self.$t("common.item_09");
        return;
      }
      if (
        !/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/.test(
          self.emailAcc
        )
      ) {
        self.emailMsg = self.$t("common.item_10");
        return;
      }
      if (!self.pwd) {
        self.pwdMsg = self.$t("common.item_13");
        return;
      }
      if (self.pwd.length < 6) {
        self.pwdMsg = self.$t("common.item_14");
        return;
      }
      if (self.checkCode && !self.code) {
        self.codeMsg = self.$t("common.item_36");
        return;
      }
      if (self.checkCode && self.code != self.validCode) {
        self.codeMsg = self.$t("common.item_37");
        return;
      }

      if (self.httpProcess) return;
      self.httpProcess = true;
      self
        .$axios({
          method: "post",
          url: "/api/register/email",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          params: {
            url: window.location.href.split("?")[0],
            email_acc: self.emailAcc,
            pwd: self.pwd,
          },
        })
        .then(() => {
          window.sessionStorage["code"] = 0;
          self.httpProcess = false;
          self.done = true;
        })
        .catch(function (error) {
          var code = parseInt(window.sessionStorage["code"]);
          if (code) {
            window.sessionStorage["code"] = code + 1;
          } else {
            window.sessionStorage["code"] = 1;
          }
          self.showCode();
          self.httpProcess = false;
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q.dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          });
        });
    },
    check(token) {
      var self = this;
      self.loading = true;
      self.$q.loading.show({
        message: self.$t("common.item_05"),
      });

      self
        .$axios({
          method: "post",
          url: "/api/register/check",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          params: { token: token },
        })
        .then(() => {
          self.$q.loading.hide();
          self.$buryPoint("user_register_suc", {});
          self.$q
            .dialog({
              message: self.$t("register.item_04"),
              ok: self.$t("common.item_03"),
            })
            .onDismiss(() => {
              self.goLogin();
            });
        })
        .catch(function (error) {
          self.$q.loading.hide();
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q
            .dialog({
              message: msg,
              ok: self.$t("common.item_03"),
            })
            .onOk(() => {
              self.loading = false;
            });
        });
    },
  },
};
</script>
